/**
 * @ngdoc directives
 * @memberof mercury.modules.time
 * @name rolefilter
 */
angular.module('mercury.modules.time.roster')
.directive('rolefilter', ["$rootScope", function($rootScope) {
  return {
    restrict: 'A',
    scope: true,
    template: '<span class="btn-group m0 role-filter" uib-dropdown>' +
      '<button class="filter-dropdown btn btn-small btn-default m0" uib-dropdown-toggle style="margin-right: 0px">' +
        'Filter Role<span class="caret"></span>' +
      '</button>' +
      '<ul uib-dropdown-menu class="dropdown-menu ">' +
        '<li ng-class="{disabled: !filter}"><a ng-click="clearRoleFilter(); $event.stopPropagation()">Clear Filter</a></li>' +
        '<li class="divider"></li>' +
        '<li ng-repeat="role in roles | filter : filterDeleted" ng-hide="role.datestampDeleted">' +
          '<a ng-click="setRoleFilter(role.id); $event.stopPropagation()" class="filter-line">' +
            '<span ng-show="filterState[role.id]"><i class="fa fa-check"></i></span> {{role.name}}' +
          '</a>' +
        '</li>' +
      '</ul>' +
    '</span>',
    link: function(scope) {
      if (typeof $rootScope.roleFilter !== 'undefined') {
        scope.filterState = $rootScope.roleFilter;
        scope.filterUpdated(scope.filterState);
      } else {
        scope.filterState = null;
      }

      /**
      * onClick event for changing a state of a roles filter.
      */
      scope.setRoleFilter = function(role) {
        if (scope.filterState === null) {
          scope.filterState = {};
        }

        scope.filterState[role] = !scope.filterState[role];
        if (scope.filterState[role] === false) {
          delete scope.filterState[role];
        }

        if (Object.keys(scope.filterState).length === 0) {
          scope.filterState = null;
        }

        scope.filterUpdated(scope.filterState);
        $rootScope.roleFilter = scope.filterState;
      };

      scope.filterDeleted = function(role) {
        return role.datestampDeleted === null;
      };

      /**
      * onClick event for clear filter option
      */
      scope.clearRoleFilter = function() {
        scope.filterState = null;
        $rootScope.roleFilter = scope.filterState;
        scope.filterUpdated(scope.filterState);
      };
    }
  };
}]);
