/**
 * @ngdoc directives
 * @memberof mercury.modules.time
 * @name editnav
 */
angular.module('mercury.modules.time.roster')
.directive('editnav', ["reporttime", "$state", "$stateParams", function(reporttime, $state, $stateParams) {

  // Note this template is inlined instead of being in a separate file as this
  // directive is also sourced by the public roster app (./Mercury/app_publicroster).
  // The build pipeline doesn't handle sharing templates between apps so this
  // is the workaround.
  const template = 
  '<span class="btn-group m0 controls-period" ng-hide="isPublicRoster" style="margin-right: 0px;">' +
    '<a ui-sref=".({report: dayLink})" class="btn btn-small btn-default" ng-class="{active: reportType == \'day\'}">' +
      'Day' +
    '</a>' +
    '<a ui-sref=".({report: weekLink})" class="btn btn-small btn-default" ng-class="{active: reportType == \'week\'}" style="margin-right: 0px;">' +
      'Week' +
    '</a>' +
  '</span>' +
  '<span rolefilter class="m0 role-filter-outer controls-role-filter" ></span>' +
  '<div ng-hide="isTemplate && reportType != \'day\'" class="btn-group m0 mr8 controls-date-selector" popover-html style="display: flex; margin-right: 0px;" >' +
    '<a ng-disabled="isTemplate && isStartDay" class="btn btn-small btn-default no-x-margin" ui-sref=".({report: prevReport})">' +
      '&lt;' +
    '</a>' +
    '<button ng-hide="isTemplate" class="btn btn-small btn-default popover-trigger" style="margin-right: 1.5px; margin-left: 1.5px;">' +
      'Select' +
    '</button>' +
    '<div class="popover">' +
      '<div inputdate ng-model="selectTime" week-picker="isWeek" ng-change="timeSelected()"></div>' +
    '</div>' +
    '<a ng-disabled="isTemplate && isEndDay" class="btn btn-small btn-default no-x-margin" ui-sref=".({report: nextReport})" style="margin-right: 0px;">' +
      '&gt;' +
    '</a>' +
  '</div>' +
  '<div ng-hide="isTemplate && reportType != \'day\'" class="btn-group m0" popover-html style="display: flex;">' +
    '<a style="align-self: center; color:#222; width:76px; text-align: center; cursor: unset;" ng-show="isTemplate && reportType == \'day\'">' +
      '{{viewStartDateTime| reporttimeformat : \'dddd\'}}' +
    '</a>' +
  '</div>' +
  '<span ng-hide="isTemplate" id="roster-date-label" class="m0 controls-selected-dates" ng-style="!isPublicRoster ? {\'margin-right\': \'10px\',\'width\': \'200px\'} : {}" style="color:#222;">' +
    '<span class="m0" ng-if="reportType == \'week\'">' +
      '{{viewStartDateTime| reporttimeformat : \'mediumDate\'}} - {{viewEndDateTime| reporttimeformat : \'mediumDate\'}}' +
    '</span>' +
    '<span class="m0" ng-if="!isTemplate && reportType == \'day\'">' +
      '{{viewStartDateTime| reporttimeformat : \'fullDate\'}}' +
    '</span>' +
  '</span>';

  return {
    restrict: 'A',
    template: template,
    scope: true,
    link: function(scope, element, attrs) {

      var reportStateParams = $stateParams.report ? JSON.parse($stateParams.report) : {};

      scope.isTemplate = attrs.isTemplate ? JSON.parse(attrs.isTemplate) : false;
      scope.isStartDay = attrs.isStartDay ? JSON.parse(attrs.isStartDay) : false;
      scope.isEndDay = attrs.isEndDay ? JSON.parse(attrs.isEndDay) : false;
      scope.isPublicRoster = attrs.isPublicRoster ? JSON.parse(attrs.isPublicRoster) : false;

      scope.isWeek = scope.isTemplate ?
        reportStateParams.period === 'week' :
        reporttime.reportType() == 'week';
      scope.selectTime = reporttime.time();

      scope.reportType = scope.isTemplate ?
        reportStateParams.period :
        reporttime.reportType();

      if (scope.isTemplate) {

        scope.dayLink = JSON.stringify({
          type: 'custom',
          start: scope.viewStartDateTime,
          end: moment(scope.viewStartDateTime).add(1, 'day').format(),
          period: 'day',
        });

        scope.weekLink = JSON.stringify({
          type: 'custom',
          start: scope.templateStartDay,
          end: scope.templateEndDay,
          period: 'week',
        });

        scope.nextReport = JSON.stringify({
          type: 'custom',
          start: moment(scope.viewStartDateTime).add(1, 'day').format(),
          end: moment(scope.viewEndDateTime).add(1, 'day').format(),
          period: 'day',
        });

        scope.prevReport = JSON.stringify({
          type: 'custom',
          start: moment(scope.viewStartDateTime).subtract(1, 'day').format(),
          end: moment(scope.viewEndDateTime).subtract(1, 'day').format(),
          period: 'day',
        });

      } else {

        const currentReportParams = reporttime.params(['time', 'type', 'role']);

        const dayLink = angular.copy(currentReportParams);
        dayLink.type = 'day';
        scope.dayLink = JSON.stringify(dayLink);

        const weekLink = angular.copy(currentReportParams);
        weekLink.type = 'week';
        scope.weekLink = JSON.stringify(weekLink);

        const nextReport = angular.copy(currentReportParams);
        nextReport.time = moment(currentReportParams.time).add(1, reporttime.reportType()).format();
        scope.nextReport = JSON.stringify(nextReport);

        const prevReport = angular.copy(currentReportParams);
        prevReport.time = moment(currentReportParams.time).subtract(1, reporttime.reportType()).format();
        scope.prevReport = JSON.stringify(prevReport);

      }

      scope.timeSelected = function() {
        var newTime = {
          type: scope.reportType,
          time: scope.selectTime
        };

        $state.go('.', {report: JSON.stringify(newTime)});
      };
    }
  };
}]);
