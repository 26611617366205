/**
 * @ngdoc services
 * @memberof mercury.services
 * @name dialog
 */
angular.module('mercury.services.dialog', [])
.factory('dialog', ["$rootScope", "$uibModal", function($rootScope, $uibModal) {
  var modalInstances = [];

  // An alert modal is tracked seperaly from other modals because we only want to show one at a time.
  var alertInstance = null;

  /**
   * Open a new dialog window
   *
   * @memberof dialog
   * @param {Object} opts An object of options to pass to the uibModal object
   */
  function open(opts) {
    var newInstance = $uibModal.open(opts);

    modalInstances.push(newInstance);

    return newInstance.result;
  }

  /**
   * Close the most recent dialog
   *
   * @memberof dialog
   * @param {Object} result Result to be passed back to the onClose promise for the dialog
   */
  function close(result) {
    if (modalInstances.length > 0) {
      var topInstance = modalInstances.pop();
      topInstance.close(result);
    }
  }

  /**
   * Helper function to open a alert dialog box.
   *
   * @memberof dialog
   * @param {String} message Message to display in the alert box
   * @param {Object} extraButtons Extra buttons to show on the alert. Key of object is button text, value is callback function.
   */
  function alert(message, extraButtons) {
    // If an alert message is already only, don't display another one.
    if (alertInstance !== null) {
      return;
    }

    var newScope = $rootScope.$new();
    newScope.message = message;
    newScope.buttons = extraButtons;

    alertInstance = $uibModal.open({
      template: '<div class="modal-body">' + message + '</div>' +
      '<div class="modal-footer">' +
      '<button ng-repeat="(label, onClick) in buttons" ng-click="onClick()" class="btn btn-primary" ng-bind="label"></button>' +
      '<button ng-click="$close()" class="btn btn-primary">Ok</button>' +
      '</div>',
      scope: newScope,
      size: 'sm'
    });

    alertInstance.result.finally(function() {
      alertInstance = null;
    });

    return alertInstance.result;
  }

  /**
   * Helper function for opening a Yes/No confirmation box
   *
   * @memberof dialog
   * @param {String} message Message to display in the alert box
   */
  function confirm(message) {
    var newScope = $rootScope.$new();
    newScope.__dialog = this;
    newScope.message = message;

    return open({
      template: '<div class="modal-body" ng-bind="message"></div>' +
      '<div class="modal-footer">' +
      '<button ng-click="__dialog.close(false)" class="btn btn-default">No</button>' +
      '<button ng-click="__dialog.close(true)" class="btn btn-primary">Yes</button>' +
      '</div>',
      scope: newScope,
      size: 'sm',
      backdrop: 'static'
    });
  }

  return {
    open: open,
    close: close,
    alert: alert,
    confirm: confirm
  };
}]);
