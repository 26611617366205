angular.module('app_publicroster', [
  'ui.bootstrap',
  'ui.router',
  'ngResource',
  /*Modules from mercury that are required */
  'mercury.startup',
  'mercury.constants',
  'mercury.modules.time.roster',
  'mercury.services.reporttime',
  'mercury.services.dialog',
  'mercury.directives.inputdate',
  'mercury.directives.popoverhtml',
  'mercury.directives.loader',
  'mercury.filters.reporttimeformat',
  /*Application modules*/
  'app_publicroster.modules.roster'
]);
