/**
 * @ngdoc run
 * @memberof mercury.startup
 * @name MomentFunctions
 * @description Define special functions for moment objects
 */
angular.module('mercury.startup')
.run(["reporttime", function(reporttime) {
  moment.fn.mResetToStartOfDay = function() {

    var origDate = this.clone();
    var daystart = reporttime.dayStart;
    var hourOffset = daystart.split(':')[0];
    var minuteOffset = daystart.split(':')[1];

    this.hour(hourOffset).minute(minuteOffset).second(0).millisecond(0);

    if (this.isAfter(origDate)) {
      return this.subtract(1, 'day');
    } else {
      return this;
    }
  };

  moment.fn.mResetDayToStartOfWeek = function() {
    var weekStart = reporttime.weekStart;
    if (this.day() < weekStart) {
      this.subtract(7, 'days');
    }
    return this.day(weekStart);
  };

  moment.fn.mStartOf = function(periodType) {
    if (periodType) {
      switch (periodType.toLowerCase()) {
        case 'week':
          return this.mResetToStartOfDay().mResetDayToStartOfWeek();
        case 'month':
          return this.mResetToStartOfDay().date(1);
        case 'halfhour':
          if (this.minutes() < 30) {
            return this.startOf('hour');
          } else {
            return this.startOf('hour').add(30, 'minutes');
          }
        break;
        case 'year':
          return this.mResetToStartOfDay().dayOfYear(1);
        case 'yesterday':
          return this.mResetToStartOfDay().subtract(1, 'day');
      }
    }
    return this.mResetToStartOfDay();
  };

  moment.fn.mEndOf = function(periodType) {
    if (periodType == 'yesterday') {
      return this.mStartOf(periodType).add(1, 'day');
    } else {
      return this.mStartOf(periodType).add(1, periodType);
    }
  };

  moment.fn.mNextIteration = function(periodType) {
    if (periodType.toLowerCase() == 'day') {
      return this.add(30, 'minutes');
    } else {
      return this.add(1, 'day');
    }
  };

  moment.fn.mUtcFormat = function() {
    return this.utc().format('YYYY-MM-DDTHH:mm:ss.SSSS[Z]');
  };

  moment.fn.mFormat = function(format) {
    switch (format) {
      case 'fullDate':
        return this.format('dddd, MMMM Do YYYY');
      case 'croppedDate':
        return this.format('ddd Do MMM. YYYY');
      case 'mediumDate':
        return this.format('ll');
      case 'shortDate':
        return this.format('D/M/YYYY');
      case 'shortTime':
        return this.format('LT');
      case 'medium':
        return this.format('ddd Do MMM, LT');
      case 'fullDateTime':
        return this.format('D MMM YYYY, h:mm A');
      case 'mediumDateTime':
        return this.format('ddd. Do MMMM YYYY, LT');

      default:
        return this.format(format);
    }
  };
}]);
