/**
 * @ngdoc filters
 * @memberof mercury.filters
 * @name avgof
 */
angular.module('mercury.filters.reporttimeformat', [])
.filter('reporttimeformat', ["reporttime", function(reporttime) {
  return function(time, format, defaultText) {
    if (time === null) {
      return defaultText || '';
    }

    return moment(time).mFormat(format || reporttime.getFormatString());
  };
}]);
