/**
 * @ngdoc directives
 * @memberof mercury.directives
 * @name inputdate
 * @description Wrapper for JQuery datapicker.
 * @attr {String} week-picker If defined, the datepicker becomes a weekPicker
 * @attr {String} inputdate-min Defines the lower limit of selectable dates
 * @attr {String} inputdate-max Defines the upper limit of selectable dates
 */
angular.module('mercury.directives.inputdate', [])
.directive('inputdate', ["$parse", "$timeout", "reporttime", function($parse, $timeout, reporttime) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, modelCtrl) {
      modelCtrl.$formatters.push(function(value) {
        if (value) {
          return moment(value).format('DD MMM YYYY');
        } else {
          return '';
        }
      });

      var isWeekPicker = false;
      if (attrs.weekPicker) {
        scope.$watch(attrs.weekPicker, function(value) {
          isWeekPicker = value;
        });
      }

      modelCtrl.$parsers.push(function(value) {
        var newDate = moment(value, 'DD MMM YYYY');

        if (modelCtrl.$modelValue) {
          var existingDate = moment(modelCtrl.$modelValue);
          newDate.hours(existingDate.hours()).minutes(existingDate.minutes()).seconds(existingDate.seconds());
        }

        if (isWeekPicker) {
          newDate.add(1, 'second').mStartOf('week');
        }

        return newDate.format();
      });

      /**
      * Helper function to add a class to the datepicker day that matches the input date
      *
      * @param {String} date - the date to add the class to within the datepicker
      * @param {String} dayClass - The class to add. If undefined will defaut to ui-state-active
      */
      var addDayClass = function(date, dayClass) {
        var momentObj = moment(date);
        findday = momentObj.date();
        findmonth = momentObj.month();
        findyear = momentObj.year();
        element.find('[data-year="' + findyear + '"][data-month="' + findmonth + '"]:contains(\'' + findday + '\')').each(function() {
          if ($(this).find('a').html() == findday) {
            $(this).find('a').addClass(dayClass);
          }
        });
      };

      /**
      * Function to update the UI to hightlight the correct dates on the datepicker to show report and compare times
      */
      var highlightDates = function() {
        if (!isWeekPicker) {
          return;
        }

        element.find('.ui-state-active').removeClass('ui-state-active');

        element.find('.dp-active').removeClass('dp-active');
        element.find('.dp-inactive').removeClass('dp-inactive');

        if (modelCtrl.$modelValue) {
          var weekStart = moment(modelCtrl.$modelValue).add(1, 'second').mStartOf('week');
          for (var iter = weekStart.clone(); iter.isBefore(weekStart.clone().add(1, 'week')); iter.add(1, 'days')) {
            addDayClass(iter, 'dp-active');
          }
        }

        element.find('.ui-datepicker-calendar tr').on('mousemove', function() {
          $(this).find('a:not(.ui-state-active)').addClass('ui-state-hover');
        });

        element.find('.ui-datepicker-calendar tr').on('mouseleave', function() {
          $(this).find('a:not(.ui-state-active)').removeClass('ui-state-hover');
        });
      };

      // presentation format, this requires setDate to be called with a Date object,
      // if you call it with a string it will interpret the date string as an offset
      // number of days and display incorrect dates
      var defaultDateFormat = 'dd M yy';

      var datepickerOptions = {
        firstDay: reporttime.weekStart,
        dateFormat: defaultDateFormat,
        showOtherMonths: true,
        selectOtherMonths: true,
        onChangeMonthYear: function() {
          $timeout(highlightDates);
        }
      };

      //A minimum date is specified
      if (attrs.inputdateMin) {
        //Set the inital options for the datepicker
        var minDate = $parse(attrs.inputdateMin)(scope);
        if (minDate) {
          datepickerOptions.minDate = moment(minDate).toDate();
        }

        //Add a watch to update the datepicker when the min date changes
        scope.$watch(attrs.inputdateMin, function(newVal, oldVal) {
          if (typeof newVal != 'undefined' && newVal != oldVal) {
            element.datepicker('option', 'minDate', moment(newVal).toDate());
          }
        });
      }

      //A maximum date is specified
      if (attrs.inputdateMax) {
        //Set the inital options for the datepicker
        var maxDate = $parse(attrs.inputdateMax)(scope);
        if (maxDate) {
          datepickerOptions.maxDate = moment(maxDate).toDate();
        }

        //Add a watch to update the datepicker when the min date changes
        scope.$watch(attrs.inputdateMax, function(newVal, oldVal) {
          if (typeof newVal != 'undefined' && newVal != oldVal) {
            element.datepicker('option', 'maxDate', moment(newVal).toDate());
          }
        });
      }

      //The element shouldn't allow text input. Only date selection
      element.attr('readonly', 'readonly');

      datepickerOptions.onSelect = function(date) {
        $timeout(function() {
          modelCtrl.$viewValue = date;
          modelCtrl.$commitViewValue();
        });

        $timeout(highlightDates);
      };

      //Initialise the date picker with the specified options.
      element.datepicker(datepickerOptions);

      //Watch the model and update the datepicker if there is a change
      scope.$watch(attrs.ngModel, function(value) {
        var setDate = value ? new Date(value) : null;

        element.datepicker('setDate', setDate);
      });

      $timeout(highlightDates);
    }
  };
}]);
