/**
 * @ngdoc directives
 * @memberof mercury.modules.time
 * @name shiftformat
 */
angular.module('mercury.modules.time.roster')
.directive('shiftformat', ["$filter", "$parse", function($filter, $parse) {
  return {
    restrict: 'A',
    scope: true,
    link: function(scope, element) {
      var roles = $parse('roles')(scope);

      var timeUpdated = function() {
        var clockinMoment = moment(scope.shift.clockinTime);
        var clockoutMoment = moment(scope.shift.clockoutTime);

        // Set the label
        element.find('.shift-label').html(clockinMoment.mFormat('HH:mm') + ' - ' + clockoutMoment.mFormat('HH:mm'));

        // Set the title attribute.
        element.attr('title', scope.shift.roleName + ' - ' + clockinMoment.mFormat('HH:mm') + ' - ' + clockoutMoment.mFormat('HH:mm'));

        // Set the jobs
        if (scope.shift.jobs) {
          // If the jobs are too long, truncate them and display ellipsis
          var jobs = JSON.parse(scope.shift.jobs).join(', ');
          if (jobs.length > 30) {
            element.find('.shift-jobs').addClass('show-div').html(jobs.substring(0, 30) + '...');
          } else {
            element.find('.shift-jobs').addClass('show-div').html(jobs);
          }
        } else {
          element.find('.shift-jobs').removeClass('show-div').html('');
        }
      };

      // Watch for a change in the shift time
      scope.$watch('shift.clockinTime', timeUpdated);
      scope.$watch('shift.clockoutTime', timeUpdated);
      scope.$watch('shift.shift.jobs', timeUpdated);

      // watch for a change in the shift role
      scope.$watch('shift.roleId', function(roleId) {
        // Set the role class which determines the color of the cell.
        element.removeClass('role-1 role-2 role-3 role-4 role-5 role-6 role-7 role-8 role-9 role-10 role-11 role-12 role-13');
        angular.forEach(roles, function(role, index) {
          if (role.id == roleId) {
            element.addClass('role-' + ((index % 13) + 1));
          }
        });
      });

      // When the filter is updated, the shift is greyed out if not matching the filter.
      scope.$watch('filter', function(newFilter) {
        if (newFilter !== null && !newFilter[scope.shift.roleId]) {
          element.addClass('inactive');
        } else {
          element.removeClass('inactive');
        }
      }, true);
    }
  };
}]);
