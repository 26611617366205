/**
 * @ngdoc directives
 * @memberof mercury.directives
 * @name loader
 * @description Display as loader on a div or button until the input promise resolves
 * @attr {Object} loader The input promise
 */
angular.module('mercury.directives.loader', [])
.directive('loader', ["$rootScope", "$q", "$parse", function($rootScope, $q, $parse) {
  return {
    restrict: 'A',
    scope: {
      loader: '='
    },
    link: function(scope, element, attrs) {
      var loaderElement = null;
      var showLoadingMessage = $parse(attrs.showLoadingMessage)(scope);
      var loaderMessage = 'That\'s a pretty large amount of data for Loaded to crunch, ' +
        'keep this tab open and check back in a few minutes and it should all be loaded up for you.';
      var customLoaderMessage = $parse(attrs.loadingMessage)(scope);
      if (customLoaderMessage) {
        loaderMessage = customLoaderMessage;
      }

      var addLoader = function() {
        element.addClass('loading-container');

        if (element.prop('tagName').toLowerCase() == 'button') {
          element.attr('disabled', 'disabled');
          loaderElement = angular.element('<i class="fa fa-spinner fa-spin"></i>');
          element.append(loaderElement);
        } else {
          var loaderHtml = '<div class="loading-overlay">' +
          '<div class="spinner">' +
          '<div class="bounce1"></div>' +
          '<div class="bounce2"></div>' +
          '<div class="bounce3"></div>' +
          '</div>';

          if (showLoadingMessage) {
            loaderHtml += '<div class="loading-message">' + loaderMessage + '</div>';
          }

          loaderHtml += '</div>';

          loaderElement = angular.element(loaderHtml);
          element.append(loaderElement);
        }
      };

      var removeLoader = function() {
        element.removeClass('loading-container');

        if (element.prop('tagName').toLowerCase() == 'button') {
          element.removeAttr('disabled');
        }

        loaderElement.remove();
        loaderElement = null;

        $rootScope.$broadcast('loading-placeholder-removed');
      };

      scope.$watch('loader', function(promise) {
        if (!promise || loaderElement !== null) {
          return;
        }

        //If the loader is an array or promises, group them into a single one.
        if (angular.isArray(promise)) {
          promise = $q.all(promise);
        }

        if (!angular.isFunction(promise.then)) {
          return;
        }

        addLoader();

        promise.then(function() {
          removeLoader();
        }, function() {
          removeLoader();
        });
      });
    }
  };
}]);
