/**
 * @ngdoc directives
 * @memberof mercury.modules.time
 * @name editorbody
 */
angular.module('mercury.modules.time.roster')
.directive('editorbody', ["$parse", "$window", "$timeout", function($parse, $window, $timeout) {
  return {
    link: function(scope, element, attrs) {
      var footerHeight = 57;

      var updateHeight = function() {
        $timeout(function() {
          var elementTop = element[0].getBoundingClientRect().top;
          var windowHeigth = (typeof $window.innerHeight != 'undefined') ? $window.innerHeight : document.documentElement.clientHeight;

          var avaliableHeight = windowHeigth - elementTop - footerHeight;

          if (angular.isDefined(attrs.maxHeight)) {
            var maxHeight = $parse(attrs.maxHeight)(scope);
            element.css('height', Math.min(maxHeight, (avaliableHeight - 20)));
          } else {
            element.css('height', (avaliableHeight - 20));
          }
        });
      };

      updateHeight();

      document.body.onresize = updateHeight;
      $(window).resize(updateHeight);

      if (angular.isDefined(attrs.maxHeight)) {
        scope.$watch(attrs.maxHeight, function(newVal, oldVal) {
          if (newVal != oldVal) {
            updateHeight();
          }
        });
      }
    }
  };
}]);
