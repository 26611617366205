/**
 * @ngdoc controllers
 * @memberof app_publicroster
 * @name TimePublicRosterViewCtrl
 * @description A read only view of the roster which can be accessed by users without an authentication.
 */
angular.module('app_publicroster.modules.roster')
.controller('TimePublicRosterViewCtrl', ["$filter", "$http", "reporttime", "$scope", "$state", "$stateParams", "settings", function($filter, $http, reporttime, $scope, $state, $stateParams, settings) {
  var REPORT_TYPE = 'week';
  reporttime.defaultType = REPORT_TYPE;
  reporttime.setAllowedTypes([REPORT_TYPE]);

  $scope.sortCol = 'name';
  $scope.filter = null;
  $scope.public = true;

  var getDayIndex = function(rosteredShift) {
    var index = null;
    for (var dayIndex in $scope.days) {
      var day = $scope.days[dayIndex];
      if (moment(rosteredShift.clockinTime).isSame(day.startTime) || moment(rosteredShift.clockinTime).isBetween(day.startTime, day.endTime)) {
        index = dayIndex;
      }
    }
    return index;
  };
  
  var updateMemberTotals = function() {
    $scope.memberTotals = {};

    angular.forEach($scope.rosteredShifts, function(rosteredShift) {
      //Only add the hours that match the filter exactly. If the staff matches the filter but this shift
      //doesn't, don't add this to the totals.
      if ($scope.isRoleShown(rosteredShift.roleId)) {
        $scope.memberTotals[rosteredShift.staffMemberId] = true;
      }
    });

    angular.forEach($scope.staff, function(member) {
      if ($scope.isRoleShown(member.defaultMemberRoleRoleId) && !$scope.memberTotals[member.id] && $scope.isStaffShown(member)) {
        $scope.memberTotals[member.id] = true;
      }
    });
  };

  $scope.loading = $http.get(settings.mercHost + '/time-roster-public/preferences', {
    params: {
      token: $stateParams.token,
      companyId: $stateParams.companyId
    }
  });

  $scope.loading.then(function(response) {
    reporttime.weekStart = response.data.weekStart;
    reporttime.dayStart = response.data.dayStart;

    moment.locale(response.data.locale);
    moment.tz.setDefault(response.data.localeTimeZone);

    // We need to set the view start and end times here because we need to use the 
    // company preferences to calculate the start and end times that we need to display
    $scope.viewStartDateTime = reporttime.start('week');
    $scope.viewEndDateTime = reporttime.end('week');

    $scope.companyName = response.data.companyName;
    $scope.loading = $http.get(settings.mercHost + '/time-roster-public', {
      params: {
        token: $stateParams.token,
        companyId: $stateParams.companyId,
        startTime: $scope.viewStartDateTime,
        endTime: $scope.viewEndDateTime
      }
    });

    $scope.loading.then(function(result) {
      $scope.rosteredShifts = result.data.rosteredShifts;
      $scope.roles = result.data.roles;
      $scope.staff = result.data.staff;

      //Build the days list for the week view.
      $scope.days = [];
      for (var i = 0; i < 7; i++) {
        var day = moment($scope.viewStartDateTime).clone().add(i, 'days');
        var startTime = day.format();
        var endTime = day.add(1, 'day').format();
        
        $scope.days.push({
          startTime: startTime,
          endTime: endTime,
          link: JSON.stringify({time: startTime, type: 'day'})
        });
      }

      $scope.shifts = {};
      angular.forEach($scope.staff, function(member) {
        $scope.shifts[member.id] = [];

        angular.forEach($scope.days, function() {
          $scope.shifts[member.id].push([]);
        });
      });

      angular.forEach($scope.rosteredShifts, function(rosteredShift) {
        var dayIndex = getDayIndex(rosteredShift);
        if (dayIndex === null) {
          return;
        }

        $scope.shifts[rosteredShift.staffMemberId][dayIndex].push(rosteredShift);

        $scope.shifts[rosteredShift.staffMemberId][dayIndex] = $filter('orderBy')(
          $scope.shifts[rosteredShift.staffMemberId][dayIndex],
          function(shift) {
            return moment(shift.clockinTime).unix();
          });

        // Check if 'breaks' data exists for the roster
        rosteredShift.hasBreaks = rosteredShift.breaks && rosteredShift.breaks.length > 0;

      });

      updateMemberTotals();
    }, function() {
      $state.go('PublicRosterError');
    });
  }, function() {
    $state.go('PublicRosterError');
  });

  /**
   * Is a role to be displayed based on the role filter.
   *
   * @returns {Boolean} true if is shown else false
   */
  $scope.isRoleShown = function(roleId) {
    return $scope.filter === null || typeof $scope.filter[roleId] != 'undefined';
  };

  $scope.isStaffShown = function(staff) {
    return staff.showInRoster && (staff.datestampDeleted === null || moment(staff.datestampDeleted).isAfter(reporttime.end()));
  };

  $scope.filterUpdated = function(newFilter) {
    $scope.filter = newFilter;

    updateMemberTotals();
  };
}]);
