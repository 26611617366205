/**
 * @ngdoc services
 * @memberof mercury.services
 * @name reporttime
 * @description Service for accessing the selected reporting period
 */
angular.module('mercury.services.reporttime')
.factory('reporttime', ["$state", "$stateParams", function($state, $stateParams) {
  // returns the current report type. One of 'day', 'week', 'month', 'custom'
  var stateParams = function() {
    if ($stateParams.report) {
      return JSON.parse($stateParams.report);
    } else {
      return {};
    }
  };

  var companyStateInformation = [];

  var setCompanyStateInformation = function(reports) {
    companyStateInformation = reports;
  };

  /**
   * Allows setting a custom format string
   *
   * @memberof reporttime
   */
  var formatString = false;

  /**
   * Report types allowed. By default, all are allowed ['day', 'week', 'month', 'custom']
   *
   * @memberof reporttime
   */
  var allowedTypes = ['day', 'week', 'month', 'custom'];

  /**
   * Set report types allowed.
   * @param {*} types
   */
  var setAllowedTypes = function(types) {
    allowedTypes = types;
  };

  /**
   * Get report types allowed, based on an earlier call to setAllowedTypes() or the defaults.
   * @returns
   */
  var getAllowedTypes = function() {
    return allowedTypes.filter(function(reportType) {
        var relevantStateInformation = companyStateInformation[$state.current.name];

        if (!relevantStateInformation) {
          // when there isn't a direct match with the current state name and the menu items
          // stored in the companyStateInformation we check if there is linked back to a
          // parent menu item, if it is we take the allowed types from that instead
          if (
            $state.current.data &&
            $state.current.data.menuLinkState &&
            companyStateInformation[$state.current.data.menuLinkState] != undefined
          ) {
            relevantStateInformation = companyStateInformation[$state.current.data.menuLinkState];
          } else {
            // if there still isn't state information available we default to the allowed
            // types, this occurs in areas where there are routes opened that don't have
            // any menu item linking at all - it's safe for these to use whatever the
            // allowed types set by the route controller are
            relevantStateInformation = {reportTimeTypes: allowedTypes};
          }
        }
        return relevantStateInformation && relevantStateInformation.reportTimeTypes.indexOf(reportType) > -1;
      });
  };

  /**
   * Default report type. Default: 'day'
   *
   * @memberof reporttime
   */
  var defaultType = 'day';

  /**
   * If set to true, time() will always return now.
   *
   * @memberof reporttime
   */
  var restrictToCurrent = false;

  /**
   * The day index which each week starts and ends.
   *
   * @memberof reporttime
   */
  var weekStart = 1;

  /**
   * The time at which each day starts and ends.
   *
   * @memberof reporttime
   */
  var dayStart = '07:00:00';

  /**
   * Get the current report type
   *
   * @param overriddenDefaultType the default type to use if the report type
   *   is not specified in the 'report' url parameter
   * @memberof reporttime
   * @return report type
   */
  function reportType(overriddenDefaultType) {
    if (
      stateParams().hasOwnProperty('type') &&
      this.getAllowedTypes().indexOf(stateParams().type) > -1
    ) {
      return stateParams().type;
    } else {
      // When a report is loaded without a specified type parameter in the
      // 'report' url parameter, we default to the default type if this is
      // allowed, otherwise we default to the first allowed type.
      //
      // Note that the default type can be overridden by the caller, and
      // if this has happened we use that as the default instead.
      if (this.getAllowedTypes().indexOf(overriddenDefaultType || this.defaultType) > -1) {
        return overriddenDefaultType || this.defaultType
      } else {
        return getAllowedTypes()[0];
      }
    }
  }

  /**
   * Get the current report time
   *
   * @memberof reporttime
   * @return report time
   */
  function reportTime() {
    if (
      stateParams().hasOwnProperty('time')
    ) {
      return stateParams().time;
    }
    return null;
  }

  /**
   * Get the current report time
   *
   * @memberof reporttime
   * @return ISO Date
   */
  function time() {
    if (stateParams().hasOwnProperty('time') && this.restrictToCurrent === false) {
      return stateParams().time;
    } else {
      // default time pointer
      return moment().format();
    }
  }

  /**
   * Get the start time for the current report type
   *
   * @param overriddenDefaultType the default type to use if the report type
   *   is not specified in the 'report' url parameter
   * @memberof reporttime
   * @return ISO date
   */
  function start(overriddenDefaultType) {
    if (this.reportType(overriddenDefaultType) == 'custom') {
      return stateParams().start;
    } else {
      return moment(this.time())
        .mStartOf(this.reportType(overriddenDefaultType))
        .format();
    }
  }

  /**
   * Get the end time for the current report type
   *
   * @param overriddenDefaultType the default type to use if the report type
   *   is not specified in the 'report' url parameter
   * @memberof reporttime
   * @return ISO Date
   */
  function end(overriddenDefaultType) {
    if (this.reportType(overriddenDefaultType) == 'custom') {
      return stateParams().end;
    } else {
      return moment(this.time())
        .mEndOf(this.reportType(overriddenDefaultType))
        .format();
    }
  }

  /**
   * Based on the report type, determine the default data interval grouping.
   *
   * @memberof reporttime
   * @return Interval format
   */
  function interval() {
    if (stateParams().hasOwnProperty('interval')) {
      return stateParams().interval;
    } else {
      switch (this.reportType()) {
        case 'day':
          return '00:30:00';
        case 'week':
        case 'month':
          return '1.00:00:00';
        case 'custom':
          var daysLength = moment(this.end()).diff(this.start(), 'days');

          if (daysLength <= 1) {
            return '00:30:00';
          } else {
            return '1.00:00:00';
          }

      }
    }
  }

  /**
   * Get an object of reporttime parameters
   *
   * @memberof reporttime
   */
  function params(fields) {
    if (!angular.isObject(fields)) {
      fields = ['start', 'end'];
    }

    var rtn = {};
    var _this = this;

    angular.forEach(fields, function(field) {
      switch (field) {
        case 'start': rtn.start = _this.start(); break;
        case 'end': rtn.end = _this.end(); break;
        case 'interval': rtn.interval = _this.interval(); break;
        case 'type': rtn.type = _this.reportType(); break;
        case 'time': rtn.time = _this.time(); break;
      }
    });
    return rtn;
  }

  /**
   * Set a custom format string to override the report settings when getFormatString is called
   * set to false to clear formatString
   *
   * @memberof reporttime
   */
  function setFormatString(string) {
    this.formatString = string;
  }

  /**
   * Based on the report type, determine the time format string for formatting a moment object.
   *
   * @memberof reporttime
   */
  function getFormatString() {
    if (this.formatString) {
      return this.formatString;
    }
    switch (this.reportType()) {
      case 'day':
        return 'h:mma';
      case 'week':
        return 'dddd';
      case 'month':
        return 'ddd Do';
      case 'custom':
        var daysLength = moment(this.end()).diff(this.start(), 'days');

        if (daysLength <= 1) {
          return 'h:mma';
        } else if (daysLength <= 7) {
          return 'dddd';
        } else {
          return 'ddd Do';
        }
    }
  }

  /**
   * Based on the report type, determine the time format string for formatting a JS Date object for google charts.
   *
   * @memberof reporttime
   */
  function getIcuFormatString() {
    if (this.formatString) {
      return this.formatString;
    }
    switch (this.reportType()) {
      case 'day':
        return 'h:mma';
      case 'week':
        return 'EEEE';
      case 'month':
        return 'EEE d';
      case 'custom':
        var daysLength = moment(this.end()).diff(this.start(), 'days');

        if (daysLength <= 1) {
          return 'h:mma';
        } else if (daysLength <= 7) {
          return 'EEEE';
        } else {
          return 'EEE d';
        }
    }
  }

  return {
    getAllowedTypes: getAllowedTypes,
    setAllowedTypes: setAllowedTypes,
    setCompanyStateInformation: setCompanyStateInformation,
    formatString: formatString,
    defaultType: defaultType,
    restrictToCurrent: restrictToCurrent,
    dayStart: dayStart,
    weekStart: weekStart,
    reportType: reportType,
    start: start,
    end: end,
    time: time,
    interval: interval,
    params: params,
    setFormatString: setFormatString,
    getFormatString: getFormatString,
    getIcuFormatString: getIcuFormatString,
    reportTime: reportTime
  };
}]);
