angular.module('mercury.services.reporttime')
.factory('comparetime', ["reporttime", "$rootScope", "$state", "$stateParams", function(reporttime, $rootScope, $state, $stateParams) {
  // returns the current report type. One of 'day', 'week', 'month', 'custom'
  var stateParams = function() {
    if ($stateParams.compare) {
      return JSON.parse($stateParams.compare);
    } else {
      return null;
    }
  };

  var comparetimeService = {
    start: function() {
      if (stateParams() === null) {
        return null;
      } else if (reporttime.reportType() == 'custom') {
        return stateParams().start;
      } else {
        return moment(this.time()).mStartOf(reporttime.reportType()).format();
      }
    },
    end: function() {
      if (stateParams() === null) {
        return null;
      } else if (reporttime.reportType() == 'custom') {
        return stateParams().end;
      } else {
        return moment(this.time()).mEndOf(reporttime.reportType()).format();
      }

    },
    time: function() {
      if (stateParams() === null) {
        return null;
      } else if (stateParams().hasOwnProperty('time')) {
        return stateParams().time;
      } else {
        // default time pointer
        return moment(reporttime.time()).subtract(1, reporttime.reportType()).format();
      }
    },
    params: function(fields) {
      if (stateParams() === null) {
        return null;
      }

      var _this = this;
      if (!angular.isObject(fields)) {
        fields = ['start', 'end'];
      }
      rtn = {};
      angular.forEach(fields, function(field) {
        switch (field) {
          case 'start': rtn.start = _this.start(); break;
          case 'end': rtn.end = _this.end(); break;
          case 'time': rtn.time = _this.time(); break;
        }
      });
      return rtn;
    }
  };

  return comparetimeService;
}]);
