/**
 * @ngdoc directives
 * @memberof mercury.directives
 * @name popoverHtml
 * @description Provides a similar interface as the bootstrapUI popover but allows HTML in the contents
 * @attr {Boolean} position-top If set, position the popover above the trigger element
 * @attr {Boolean} position-right If set, right align the popover element
 * @attr {Boolean} is-open Specifies if the popover is open or not.
 */
angular.module('mercury.directives.popoverhtml', [])
.directive('popoverHtml', ["$document", "$parse", "$templateCache", "$timeout", function($document, $parse, $templateCache, $timeout) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var isOpen = false;

      var popoverElement = $(element).find('.popover');
      var popoverTrigger = $(element).find('.popover-trigger');

      popoverElement.hide();

      var updatePosition = function() {
        var topPos = popoverTrigger.outerHeight();
        var leftPos = 0;
        var width = $(window).width();

        if (angular.isDefined(attrs.positionTop)) {
          topPos -= popoverElement.outerHeight();

          //Get the element that the popover was appended to.
          topPos -= popoverTrigger.outerHeight();
        }

        if (angular.isDefined(attrs.positionRight)) {
          leftPos -= popoverElement.outerWidth() - $(element).outerWidth();
        }

        //Apply any position changes.
        if (width <= 992) {
          popoverElement.css('right', '0px');
          popoverElement.css('left', 'auto');
          popoverElement.css('top', '30px');

        } else {
          if (angular.isDefined(attrs.topPosition)) {
            topPos = attrs.topPosition;
          }

          if (angular.isDefined(attrs.leftPosition)) {
            leftPos = attrs.leftPosition;
          }

          popoverElement.css('top', topPos + 'px');
          popoverElement.css('left', leftPos + 'px');

          if (angular.isDefined(attrs.rightPosition)) {
            popoverElement.css('right', attrs.rightPosition + 'px');
          }
        }
      };

      var close = function() {
        if (isOpen) {
          isOpen = false;
          if (attrs.isOpen) {
            $parse(attrs.isOpen).assign(scope, false);
          }

          //Remove the document events added for this popup.
          $($document).off('mousedown.loadedPopover');

          popoverElement.hide();
        }
      };

      var open = function() {
        if (!isOpen) {
          isOpen = true;

          if (attrs.isOpen) {
            $parse(attrs.isOpen).assign(scope, true);
          }

          updatePosition();

          //Give the date and time pickers to initialize then show the popover.
          $timeout(function() {
            popoverElement.show();
          }, 1);

          /**
          * If there is a click anywhere outside of this directive, close the popup
          */
          $($document).on('mousedown.loadedPopover', function(e) {
            var targetNotInput = $(e.target).get(0) != popoverTrigger.get(0);
            var targetNotPopup = e.target != popoverElement.get(0) && popoverElement.find(e.target).length === 0;

            if (targetNotInput && targetNotPopup) {
              close();
            }
          });
        }
      };

      if (attrs.isOpen) {
        scope.$watch(attrs.isOpen, function(isOpen) {
          if (isOpen) {
            open();
          } else {
            close();
          }
        });
      }

      popoverTrigger.on('click', function() {
        $timeout(function() {
          open();
        });
      });

      scope.$on('popoverResize', function() {
        if (isOpen) {
          updatePosition();
        }
      });
    }
  };
}]);
