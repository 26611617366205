angular.module('app_publicroster.modules.roster')
.config(["$stateProvider", "$urlRouterProvider", function($stateProvider, $urlRouterProvider) {
  $stateProvider.state('PublicRoster', {
    url: '/roster/:companyId/:token?:report',
    templateUrl: 'modules/roster/view.tpl.html',
    controller: 'TimePublicRosterViewCtrl'
  });

  $stateProvider.state('PublicRosterError', {
    url: '/error',
    templateUrl: 'modules/roster/error.tpl.html'
  });

  $urlRouterProvider.otherwise('/error');
}]);
